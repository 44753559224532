html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #c0d2e7;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #92aac5;
  border-radius: 20px;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: 0;
  height: 4px;
}

.swiper-scrollbar-drag {
  background: none;
}

.swiper-scrollbar-drag::after {
  content: "";
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #3297d0;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-primary {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 11px 23px;
  display: inline-block;
  border-radius: 4px;
  background-color: #227ee0;
  transition: 0.3s all;
  color: #fff;
  border: 1px solid transparent;
}

.btn-primary:hover {
  background-color: #1c76d4;
}

.btn-secondary {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 11px 23px;
  display: inline-block;
  border-radius: 4px;
  background-color: #fff;
  transition: 0.3s all;
  border: 1px solid #227ee0;
  color: #121921;
}

.btn-secondary:hover {
  background-color: #f4f9fd;
}

.btn-secondary:focus {
  background-color: #e4f0fb;
}
.btn-secondary:disabled {
  opacity: 0.18;
  pointer-events: none;
}
.container {
  max-width: 1250px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
