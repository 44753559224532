.footer {
  color: #fff;
}

.footer__top {
  padding-top: 41px;
  padding-bottom: 41px;
  background-color: #0d2843;
}
.footer__contact {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0 0 24px;
}

.footer__t {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
}

.footer__top__flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer__menu {
  margin-left: 64px;
  &:first-child {
    margin-left: 0;
  }
}

.footer__ul {
  li {
    margin-bottom: 8px;
  }
  a {
    color: #5e7e9d;
    transition: 0.3s all;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    &:hover {
      color: #a3bcd4;
    }
    &:focus {
      color: #fff;
    }
  }
}

.footer__ul--email {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 44px;
}

.footer__soc {
  flex: 1;
  ul {
    display: flex;
    justify-content: center;
    li {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  a {
    svg {
      display: block;
      transition: 0.3s all;
      fill: #5e7e9d;
    }
    &:hover {
      svg {
        fill: #a3bcd4;
      }
    }
  }
}

.footer__top__action {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 24px;
}

@media (max-width: 1199px) {
  .footer__top__action {
    width: 100%;
    display: flex;
    gap: 0;
    margin: 0 -12px;
    margin-top: 24px;
    a {
      margin: 0 12px;
    }
  }
  .footer__soc {
    flex: none;
    margin-left: 50px;
  }
}

@media (max-width: 991px) {
  .footer__soc {
    width: 100%;
    margin-top: 24px;
    margin-left: 0;
    ul {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 600px) {
  .footer__soc {
    margin-top: 0;
    ul {
      justify-content: space-between;
      li {
        margin: 0;
      }
      svg {
        height: 41px;
        width: 41px;
      }
    }
  }
  .footer__contact {
    font-size: 21px;
    line-height: 32px;
  }
  .footer__menu {
    margin: 0;
    width: 100%;
    &:first-child {
      margin-top: 0;
    }
  }
  .footer__ul--email {
    grid-template-columns: auto;
  }
  .footer__ul--phone {
    display: flex;
    li {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .footer__t {
    font-size: 16px;
    line-height: 24px;
  }
  .footer__top__action {
    width: calc(100% + 24px);
    a {
      width: calc(50% - 24px);
    }
  }
}

@media (max-width: 374px) {
  .footer__top__action {
    flex-wrap: wrap;
    a {
      width: calc(100% - 24px);
      &:first-child {
        margin-bottom: 12px;
      }
    }
  }
}

.footer__bot {
  background-color: #111e2c;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer__bot__nav {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));

  column-gap: 122px;
  row-gap: 24px;
}

.footer__t2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 12px;
}

.footer__bot .footer__ul {
  a {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 1249px) {
  .footer__bot__nav {
    column-gap: 50px;
  }
}
.footer__bot__nav--mob {
  display: none;
}
@media (max-width: 991px) {
  .footer__bot__nav {
    display: none;
  }
  .footer__bot__nav--mob {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
  .footer__ul {
    margin-bottom: 24px;
  }
  .footer__t2 {
    font-size: 14px;
    line-height: 21px;
  }
}

.footer__bot__flex {
  padding-top: 46px;
  display: flex;
  justify-content: space-between;
}

.footer__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5e7e9d;
}

.footer__policy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    color: #5e7e9d;
    transition: 0.3s all;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    &:hover {
      color: #a3bcd4;
    }
    &:focus {
      color: #fff;
    }
  }
}
.footer__policy__dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #5e7e9d;
  margin: 0 12px;
}

@media (max-width: 767px) {
  .footer__bot__flex {
    padding-top: 24px;
    border-top: 1px solid #5e7e9d;
    flex-direction: column;
    align-items: center;
  }
  .footer__text {
    margin-bottom: 12px;
  }
  .footer__policy {
    justify-content: center;
  }
  .footer__bot {
    padding-bottom: 24px;
  }
}
