@charset "UTF-8";
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #c0d2e7;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #92aac5;
  border-radius: 20px;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: 0;
  height: 4px;
}

.swiper-scrollbar-drag {
  background: none;
}

.swiper-scrollbar-drag::after {
  content: "";
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #3297d0;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-primary {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 11px 23px;
  display: inline-block;
  border-radius: 4px;
  background-color: #227ee0;
  transition: 0.3s all;
  color: #fff;
  border: 1px solid transparent;
}

.btn-primary:hover {
  background-color: #1c76d4;
}

.btn-secondary {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 11px 23px;
  display: inline-block;
  border-radius: 4px;
  background-color: #fff;
  transition: 0.3s all;
  border: 1px solid #227ee0;
  color: #121921;
}

.btn-secondary:hover {
  background-color: #f4f9fd;
}

.btn-secondary:focus {
  background-color: #e4f0fb;
}

.btn-secondary:disabled {
  opacity: 0.18;
  pointer-events: none;
}

.container {
  max-width: 1250px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0d2843;
  z-index: 5;
}

.header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header__ul {
  display: flex;
}

.header__li {
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-right: 24px;
  cursor: pointer;
  transition: 0.3s all;
  position: relative;
}
.header__li:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #66acf5;
  opacity: 0;
  transition: 0.3s all;
}
.header__li:last-child {
  margin-right: 0;
}
.header__li > svg {
  transition: 0.2s all;
  margin-left: 4px;
}
.header__li:hover {
  color: #66acf5;
}
.header__li:hover:after {
  opacity: 1;
}
.header__li:hover > svg {
  transform: rotate(180deg);
}

.header__phone {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #fff;
  display: block;
  transition: 0.3s all;
  margin-right: 24px;
}
.header__phone:hover {
  color: #66acf5;
}

.header__right {
  display: flex;
  align-items: center;
}

.header__btn {
  padding: 6px 16px;
  border-radius: 6px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.header__btn:last-child {
  margin-right: 0;
}

.header__li__menu {
  display: none;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  padding: 38px 0;
  background-color: rgba(17, 30, 44, 0.85);
}

.header__li:hover .header__li__menu {
  display: block;
}

.header__li:hover .header__li__menu2 {
  display: block;
}

.header__p {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 39px;
}

.header__p__item {
  padding: 17px 16px;
  border-radius: 9.5px;
  color: #121921;
  transition: 0.3s all;
}

.header__p__t {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 6px;
}

.header__p__text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.header__p__item--eld {
  background-color: #dfffff;
}
.header__p__item--eld:hover {
  background-color: #aeffff;
}

.header__p__item--dh {
  background-color: #e0ffe9;
}
.header__p__item--dh:hover {
  background-color: #aeffc5;
}

.header__p__item--gps {
  background-color: #fff4df;
}
.header__p__item--gps:hover {
  background-color: #ffe4ae;
}

.header__c {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.header__c__item {
  height: 132px;
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(18, 25, 33);
  background-repeat: no-repeat;
  transition: 0.3s all;
}
.header__c__item svg {
  margin-bottom: 16px;
  display: block;
}

.header__c__item--1 {
  background-color: rgb(223, 255, 255);
  background-image: url("../img/header/1.svg");
  background-position: right bottom;
}
.header__c__item--1:hover {
  background-color: #aeffff;
}

.header__c__item--2 {
  background-color: rgb(224, 255, 233);
  background-image: url("../img/header/2.svg");
  background-position: right bottom;
}
.header__c__item--2:hover {
  background-color: #aeffc5;
}

.header__c__item--3 {
  background-color: rgb(255, 244, 223);
  background-image: url("../img/header/3.svg");
  background-position: center;
  background-size: cover;
}
.header__c__item--3:hover {
  background-color: #ffe4ae;
}

.header__c__item--4 {
  background-color: rgb(224, 239, 255);
  background-image: url("../img/header/4.svg");
  background-position: center;
  background-size: cover;
}
.header__c__item--4:hover {
  background-color: #aed5ff;
}

.header__c__item--5 {
  background-color: rgb(230, 224, 255);
  background-image: url("../img/header/5.svg");
  background-position: left bottom;
}
.header__c__item--5:hover {
  background-color: #beafff;
}

.header__c__item--6 {
  background-color: rgb(255, 244, 223);
  background-image: url("../img/header/6.svg");
  background-position: left bottom;
}
.header__c__item--6:hover {
  background-color: #ffe4ae;
}

.header__s {
  display: flex;
  align-items: center;
}
.header__s .header__c {
  grid-template-columns: repeat(4, 1fr);
}
.header__s .header__c__item {
  height: 107px;
}
.header__s .header__c__item svg {
  margin-bottom: 6px;
}

.header__partners {
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.24);
  padding-left: 24px;
  margin-left: 24px;
  height: 107px;
}

.header__partners__text {
  max-width: 66px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-right: 24px;
  color: #fff;
}

.header__partners__img__2 {
  display: none;
}

.header__partners__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 20px;
}

.header__partners__img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  width: 159px;
  height: 45px;
}
.header__partners__img span {
  margin-left: 10px;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(63, 63, 63);
}
.header__partners__img:hover .header__partners__img__1 {
  display: none;
}
.header__partners__img:hover .header__partners__img__2 {
  display: block;
}

.header__li__menu2 {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(17, 30, 44);
  display: none;
  border-radius: 0 0 12px 12px;
}
.header__li__menu2 li {
  border-bottom: 1px solid rgb(48, 67, 86);
}
.header__li__menu2 li:last-child {
  border-bottom: 0;
}
.header__li__menu2 a {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  display: block;
  padding: 11px 16px;
  white-space: nowrap;
}
.header__li__menu2 a:hover {
  color: #66acf5;
}

.header__mob {
  display: none;
}

.header__logo__mob {
  display: none;
}

.mob__menu {
  display: none;
}

@media (max-width: 1249px) {
  .header {
    height: 70px;
    display: flex;
    align-items: center;
  }
  .header__mob {
    display: flex;
    align-items: center;
  }
  .header__right {
    display: none;
  }
  .header__nav {
    display: none;
  }
  .header__avatar {
    margin-right: 16px;
  }
  .burger {
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .burger.active .burger__open {
    display: none;
  }
  .burger.active .burger__close {
    display: block;
  }
  .burger__close {
    display: none;
  }
  .header.active .burger__open {
    display: none;
  }
  .header.active .burger__close {
    display: block;
  }
  .header.active .header__avatar {
    display: none;
  }
  .header.active .header__btn {
    display: none;
  }
  .header.active .header__logo__des {
    display: block;
  }
  .header.active .header__logo__mob {
    display: none;
  }
  .mob__menu__top {
    background-color: #e9f2fc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 9px;
    border-radius: 0 0 10px 10px;
  }
  .mob__menu__top a {
    margin: 0 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    color: #121921;
  }
  .mob__menu__top a svg {
    margin-right: 12px;
  }
  .mob__menu {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-color: #fff;
    overflow: auto;
    display: none;
  }
  .mob__menu.active {
    display: block;
  }
  .mob__menu__person {
    padding: 0 15px;
    margin-top: 26px;
    margin-bottom: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mob__menu__avatar {
    display: flex;
    align-items: center;
  }
  .mob__menu__avatar img {
    margin-right: 12px;
  }
  .mob__menu__avatar button {
    color: #227ee0;
  }
  .mob__menu__actions {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 8px;
  }
  .mob__menu__logout,
  .mob__menu__enter {
    width: 35px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
  }
  .mob__menu__bot {
    background-color: #eaf6ff;
    padding: 11px 15px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 24px;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .mob__menu__list {
    padding: 0 15px;
    padding-bottom: 90px;
  }
  .mob__menu__list__btn {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;
    border: 0;
    padding-right: 30px;
    transition: 0.3s all;
  }
  .mob__menu__list__icon {
    margin-right: 6px;
    transition: 0.3s all;
  }
  .mob__menu__list__v {
    position: absolute;
    top: 12px;
    right: 12px;
    transition: 0.3s all;
    color: #bdbdbd;
  }
  .mob__menu__list__ul {
    padding-left: 24px;
    display: none;
  }
  .mob__menu__list__ul a {
    border-left: 1px solid #bdbdbd;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #414141;
    padding: 12.5px 24px;
    display: block;
  }
  .mob__menu__list__item.active .mob__menu__list__icon {
    fill: #fff;
  }
  .mob__menu__list__item.active .mob__menu__list__btn {
    color: #fff;
    background-color: #1da5ff;
  }
  .mob__menu__list__item.active .mob__menu__list__v {
    color: #fff;
    transform: rotate(180deg);
  }
  .mob__menu__list__item.active .mob__menu__list__ul {
    display: block;
  }
}
@media (max-width: 767px) {
  .header__logo__des {
    display: none;
  }
  .header__logo__mob {
    display: block;
  }
}
@media (max-width: 374px) {
  .mob__menu__top a {
    font-size: 12px;
  }
  .mob__menu__top a svg {
    height: 25px;
    width: 25px;
  }
  .mob__menu__bot {
    gap: 10px;
  }
  .mob__menu__bot a {
    font-size: 14px;
  }
}
.user {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #eaf7ff;
  border-radius: 4px;
  display: none;
}

.user.active {
  display: flex;
}

.user__link {
  display: flex;
  align-items: center;
}
.user__link > img {
  min-width: 20px;
  margin-right: 5px;
}

.user__name {
  padding-right: 5px;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0em;
  max-width: 42px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  word-break: break-word;
}

.user__actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.user__actions {
  display: grid;
  grid-template-columns: repeat(2, 19px);
  gap: 4.3px;
}

.user__actions__btn {
  padding: 0;
  border: 0;
  width: 19px;
  height: 19px;
  border-radius: 3.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mob__menu__logout {
  background-color: #f2f2f2;
}

.mob__menu__enter {
  background-color: #227ee0;
}

.btn-open-login.hide {
  display: none;
}

@media (max-width: 1249px) {
  .user {
    border-radius: 6px;
    padding: 8px;
    margin-right: 18px;
  }
  .user .user__link > img {
    width: 39px;
    height: 39px;
  }
  .user .user__name {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.01em;
    max-width: 70px;
  }
  .user .user__actions {
    grid-template-columns: repeat(2, 28px);
  }
  .user .user__actions__btn {
    width: 28px;
    height: 28px;
  }
  .user .user__actions__btn img {
    width: 19px;
    height: 19px;
  }
  .header.active .user {
    display: none;
  }
  .mob__menu__avatar .user {
    padding: 0;
    background-color: transparent;
  }
  .mob__menu__avatar .user .user__name {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    max-width: initial;
  }
}
.mainsection .label {
  padding: 12px 19px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #121921;
  display: inline-block;
  border-radius: 3px;
}
.mainsection .sec {
  padding-top: 168px;
  padding-bottom: 168px;
  min-height: 680px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.mainsection .sec__des,
.mainsection .sec__mob {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.mainsection .sec__mob {
  display: none;
}
@media (max-width: 575px) {
  .mainsection .sec__mob {
    display: block;
  }
  .mainsection .sec__des {
    display: none;
  }
}
@media (min-width: 1440px) {
  .mainsection .sec {
    min-height: calc(100vh - 60px);
  }
}
@media (min-width: 2400px) {
  .mainsection .sec {
    min-height: 1200px;
  }
}
.mainsection .wrap {
  position: relative;
  z-index: 2;
}
.mainsection .wrap h1 {
  font-size: 100px;
  font-weight: 500;
  line-height: 130px;
  margin: 0;
  color: #fff;
}
.mainsection .wrap h1 span {
  font-size: 80px;
  font-weight: 400;
  line-height: 82px;
  display: block;
}
.mainsection .des {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 30px;
  color: #fff;
}
.mainsection .des svg {
  fill: #fff;
  position: relative;
  top: 4px;
  margin-right: 12px;
  display: inline-block;
}
.mainsection .btns {
  display: flex;
  align-items: flex-start;
}
.mainsection .btns a {
  margin-right: 24px;
  font-weight: 500;
}
.mainsection .smartLine {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 1;
}
@media (max-width: 767px) {
  .mainsection .label {
    margin-bottom: 16px;
  }
  .mainsection .smartLine {
    display: none;
  }
  .mainsection .sec {
    padding-top: 232px;
    padding-bottom: 25px;
    min-height: 488px;
  }
  .mainsection .wrap h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 12px;
  }
  .mainsection .wrap h1 span {
    font-size: 27px;
    line-height: 32px;
  }
  .mainsection .des {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    max-width: 400px;
  }
  .mainsection .des br {
    display: none;
  }
  .mainsection .btns {
    flex-direction: column;
  }
  .mainsection .btns a {
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
  }
  .mainsection .btns a:last-child {
    margin-bottom: 0;
  }
  .mainsection .sec--post {
    padding-bottom: 46px;
    min-height: initial;
  }
  .mainsection .sec--post .des {
    margin-bottom: 0;
  }
}

.breadcrumb {
  padding-top: 48px;
  padding-bottom: 38px;
}

.breadcrumb__ul {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumb__ul li {
  margin-bottom: 10px;
  padding-right: 10px;
  position: relative;
  color: #262626;
}
.breadcrumb__ul li:after {
  content: "";
  width: 2px;
  height: 2px;
  position: absolute;
  display: block;
  background-color: #333a43;
  top: 50%;
  right: 4px;
}
.breadcrumb__ul li:last-child:after {
  display: none;
}
.breadcrumb__ul a,
.breadcrumb__ul span {
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}
.breadcrumb__ul a {
  color: #227ee0;
}
.breadcrumb__ul a:hover {
  text-decoration: underline;
}

.breadcrumb__home svg {
  display: none;
}

@media (max-width: 767px) {
  .breadcrumb {
    padding-top: 24px;
  }
  .breadcrumb__ul li {
    padding-right: 28px;
  }
  .breadcrumb__ul li:after {
    right: 13px;
  }
  .breadcrumb__ul .breadcrumb__home span {
    display: none;
  }
  .breadcrumb__ul .breadcrumb__home svg {
    display: block;
    top: 1px;
    position: relative;
  }
}
.recent__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  color: #111e2c;
  margin: 0;
  margin-bottom: 40px;
}
.recent__title a {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #2174cd;
  display: inline-flex;
}
.recent__title a:hover {
  text-decoration: underline;
}
.recent__title a svg {
  margin-left: 8px;
  fill: #2174cd;
}

@media (max-width: 767px) {
  .recent__title {
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
  }
}
.preview {
  width: 317px;
  height: 170px;
  display: block;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 40px;
}
.preview:last-child {
  margin-bottom: 0;
}
.preview:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 1;
  transition: 0.3s ease-in-out;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  height: 50%;
}

.previw__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  max-width: none;
  display: block;
}

.previw__icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.preview__bot {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.preview__bot svg {
  fill: #fff;
  transition: 0.3s ease-in-out;
}

.previw__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.preview:hover:before {
  height: 100%;
}
.preview:hover .previw__img--vector {
  transform: scale(1.2);
}
.preview:hover .preview__bot svg {
  width: 26px;
  height: 26px;
}
.preview:hover .previw__title {
  font-size: 17px;
  line-height: 26px;
}

.preview--big {
  width: 480px;
  height: 380px;
}

@media (max-width: 767px) {
  .preview--big {
    width: 324px;
    height: 356px;
  }
  .preview {
    margin-bottom: 16px;
  }
}
@media (max-width: 374px) {
  .preview {
    width: 265px;
    height: 140px;
  }
  .preview--big {
    height: 296px;
  }
}
.popular {
  padding-top: 48px;
  overflow: hidden;
}

.preview--popular {
  width: 480px;
  height: 380px;
}

.popular .swiper {
  overflow: visible;
}

@media (max-width: 767px) {
  .popular {
    padding-top: 38px;
  }
  .preview--popular {
    width: 324px;
    height: 170px;
  }
}
@media (max-width: 374px) {
  .preview--popular {
    width: 265px;
    height: 140px;
  }
}
.action-sec .sec {
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
}
.action-sec .secDark {
  background-color: rgb(13, 40, 67);
  color: rgb(255, 255, 255);
}
.action-sec .secLight {
  color: rgb(13, 40, 67);
  background-color: rgb(255, 255, 255);
}
.action-sec .secLight .t1 {
  color: rgba(18, 25, 33, 0.36);
}
.action-sec .bot {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.action-sec .bot a {
  margin: 0 12px;
  font-weight: 500;
}
.action-sec .wrap {
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
}
.action-sec .t1 {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
.action-sec .lightt1 {
  color: rgba(18, 25, 33, 0.36);
}
.action-sec .darkt1 {
  color: rgba(255, 255, 255, 0.36);
}
.action-sec .t2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 47px;
  margin-bottom: 24px;
}
.action-sec .t3 {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
}
.action-sec .t3 a {
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
  color: #fff;
}
.action-sec .light a {
  color: #227ee0;
}
@media (max-width: 767px) {
  .action-sec .t1 {
    font-size: 18px;
    line-height: 24px;
    max-width: 317px;
    margin-left: auto;
    margin-right: auto;
  }
  .action-sec .t3 {
    font-size: 21px;
    line-height: 38px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .action-sec .t3 a {
    display: block;
  }
  .action-sec .t2 {
    font-size: 21px;
    line-height: 27px;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
  }
  .action-sec .bot {
    flex-direction: column;
  }
  .action-sec .bot a {
    margin: 0;
    margin-bottom: 16px;
    display: block;
    width: 100%;
  }
  .action-sec .bot a:last-child {
    margin-bottom: 0;
  }
  .action-sec .sec {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.about {
  padding-top: 40px;
}

.about__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 0 0 40px;
}

.about__item {
  background-color: #111e2c;
  border-radius: 12px;
  display: flex;
  padding: 29px 21px 24px 24px;
}

.about__img {
  display: block;
  flex: 0 0 213px;
  background-color: #f4faff;
  border-radius: 12px;
  padding: 20px;
  overflow: hidden;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #121921;
  margin-right: 24px;
  transition: 0.3s all;
}
.about__img:hover {
  background-color: #f4f9fd;
}
.about__img img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.about__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 24px;
  color: #fff;
}

.about__content {
  flex: 1;
}

.about__bot {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.about__bot .btn-primary {
  padding: 11px 20px;
}
.about__bot .about__more {
  background-color: transparent;
}

.about__grid {
  margin: 0 -15px;
  padding: 0 15px;
}

@media (min-width: 1250px) {
  .about__grid .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 46px;
  }
}
.about .swiper__bot {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 1249px) {
  .about__item {
    width: 324px;
    flex-direction: column;
    padding: 24px;
  }
  .about__img {
    flex: auto;
    margin-right: 0;
    width: 100%;
    height: 179px;
    margin-bottom: 24px;
  }
  .about__content {
    flex: auto;
  }
  .about__bot {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
  }
  .about__bot .btn-primary:last-child {
    margin-bottom: 12px;
  }
  .about .swiper__bot {
    opacity: 1;
    pointer-events: all;
  }
}
@media (max-width: 767px) {
  .about__title {
    font-size: 21px;
    line-height: 32px;
    margin-bottom: 33px;
  }
}
@media (max-width: 374px) {
  .about__item {
    width: 280px;
    padding: 20px 15px;
  }
}
.download-sec .bot__right {
  border-radius: 6px;
  overflow: hidden;
}
.download-sec .bot__right img {
  display: block;
}
.download-sec .download {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #111e2c;
  color: #fff;
}
@media (max-width: 767px) {
  .download-sec .download {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.download-sec .download h2 {
  font-size: 64px;
  font-weight: 500;
  line-height: 83px;
  margin-bottom: 8px;
}
.download-sec .download h2 .mini {
  font-size: 34px;
  font-weight: 500;
  line-height: 44px;
}
.download-sec .download h2 .blue {
  color: rgb(33, 116, 205);
}
.download-sec .flex {
  display: flex;
  align-items: center;
}
.download-sec .flex__left {
  flex: 1;
  padding-right: 18px;
}
.download-sec .flex__right {
  width: 616px;
}
.download-sec .benefits {
  display: flex;
  align-items: flex-start;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: rgb(157, 178, 200);
  text-transform: uppercase;
  margin-bottom: 40px;
}
.download-sec .benefits__col {
  margin-right: 15px;
  margin-bottom: 16px;
}
.download-sec .bot {
  display: flex;
  align-items: flex-start;
}
.download-sec .bot__link {
  margin-bottom: 16px;
  display: block;
  transition: 0.3s all;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid rgba(33, 116, 205, 0.24);
}
.download-sec .bot__link img {
  width: 188px;
  height: 57px;
  display: block;
}
.download-sec .bot__link:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.download-sec .bot__link:last-child {
  margin-bottom: 0;
}
.download-sec .bot__left {
  margin-right: 20px;
}
.download-sec .video {
  display: block;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.3s all;
}
.download-sec .video__pl {
  max-width: 100%;
}
.download-sec .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 82px;
  border-radius: 100%;
  background-color: rgb(255, 255, 255);
  padding-left: 7px;
}
@media (max-width: 1199px) {
  .download-sec .flex__right {
    width: 500px;
  }
}
@media (max-width: 1023px) {
  .download-sec .flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .download-sec .flex__left {
    flex: auto;
    width: 100%;
    padding-right: 0;
    margin-bottom: 16px;
    text-align: center;
  }
  .download-sec .benefits {
    justify-content: center;
    margin-bottom: 16px;
  }
  .download-sec .benefits__col {
    margin: 0 15px;
  }
  .download-sec .bot {
    justify-content: center;
  }
  .download-sec .bot__right {
    display: none;
  }
  .download-sec .bot__left {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 24px;
    margin-right: 0;
  }
  .download-sec .bot__link {
    margin-bottom: 0;
  }
  .download-sec .flex__right {
    width: 616px;
    max-width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .download-sec .download h2 {
    font-size: 43px;
    line-height: 56px;
  }
  .download-sec .download h2 .mini {
    font-size: 24px;
    line-height: 31px;
  }
  .download-sec .benefits__col {
    font-size: 16px;
    line-height: 24px;
  }
  .download-sec .bot__link img {
    width: 159px;
    height: 45px;
  }
}
@media (max-width: 400px) {
  .download-sec .download h2 {
    font-size: 36px;
    line-height: 46px;
  }
  .download-sec .download h2 .mini {
    font-size: 18px;
    line-height: 28px;
  }
  .download-sec .bot__left {
    grid-template-columns: repeat(1fr, auto);
  }
  .download-sec .bot__link img {
    width: 100%;
    height: auto;
  }
}

.footer {
  color: #fff;
}

.footer__top {
  padding-top: 41px;
  padding-bottom: 41px;
  background-color: #0d2843;
}

.footer__contact {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0 0 24px;
}

.footer__t {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
}

.footer__top__flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer__menu {
  margin-left: 64px;
}
.footer__menu:first-child {
  margin-left: 0;
}

.footer__ul li {
  margin-bottom: 8px;
}
.footer__ul a {
  color: #5e7e9d;
  transition: 0.3s all;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.footer__ul a:hover {
  color: #a3bcd4;
}
.footer__ul a:focus {
  color: #fff;
}

.footer__ul--email {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 44px;
}

.footer__soc {
  flex: 1;
}
.footer__soc ul {
  display: flex;
  justify-content: center;
}
.footer__soc ul li {
  margin-right: 12px;
}
.footer__soc ul li:last-child {
  margin-right: 0;
}
.footer__soc a svg {
  display: block;
  transition: 0.3s all;
  fill: #5e7e9d;
}
.footer__soc a:hover svg {
  fill: #a3bcd4;
}

.footer__top__action {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 24px;
}

@media (max-width: 1199px) {
  .footer__top__action {
    width: 100%;
    display: flex;
    gap: 0;
    margin: 0 -12px;
    margin-top: 24px;
  }
  .footer__top__action a {
    margin: 0 12px;
  }
  .footer__soc {
    flex: none;
    margin-left: 50px;
  }
}
@media (max-width: 991px) {
  .footer__soc {
    width: 100%;
    margin-top: 24px;
    margin-left: 0;
  }
  .footer__soc ul {
    justify-content: flex-start;
  }
}
@media (max-width: 600px) {
  .footer__soc {
    margin-top: 0;
  }
  .footer__soc ul {
    justify-content: space-between;
  }
  .footer__soc ul li {
    margin: 0;
  }
  .footer__soc ul svg {
    height: 41px;
    width: 41px;
  }
  .footer__contact {
    font-size: 21px;
    line-height: 32px;
  }
  .footer__menu {
    margin: 0;
    width: 100%;
  }
  .footer__menu:first-child {
    margin-top: 0;
  }
  .footer__ul--email {
    grid-template-columns: auto;
  }
  .footer__ul--phone {
    display: flex;
  }
  .footer__ul--phone li {
    margin-right: 8px;
  }
  .footer__ul--phone li:last-child {
    margin-right: 0;
  }
  .footer__t {
    font-size: 16px;
    line-height: 24px;
  }
  .footer__top__action {
    width: calc(100% + 24px);
  }
  .footer__top__action a {
    width: calc(50% - 24px);
  }
}
@media (max-width: 374px) {
  .footer__top__action {
    flex-wrap: wrap;
  }
  .footer__top__action a {
    width: calc(100% - 24px);
  }
  .footer__top__action a:first-child {
    margin-bottom: 12px;
  }
}
.footer__bot {
  background-color: #111e2c;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer__bot__nav {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  column-gap: 122px;
  row-gap: 24px;
}

.footer__t2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 12px;
}

.footer__bot .footer__ul a {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 1249px) {
  .footer__bot__nav {
    column-gap: 50px;
  }
}
.footer__bot__nav--mob {
  display: none;
}

@media (max-width: 991px) {
  .footer__bot__nav {
    display: none;
  }
  .footer__bot__nav--mob {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
  .footer__ul {
    margin-bottom: 24px;
  }
  .footer__t2 {
    font-size: 14px;
    line-height: 21px;
  }
}
.footer__bot__flex {
  padding-top: 46px;
  display: flex;
  justify-content: space-between;
}

.footer__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5e7e9d;
}

.footer__policy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.footer__policy a {
  color: #5e7e9d;
  transition: 0.3s all;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.footer__policy a:hover {
  color: #a3bcd4;
}
.footer__policy a:focus {
  color: #fff;
}

.footer__policy__dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #5e7e9d;
  margin: 0 12px;
}

@media (max-width: 767px) {
  .footer__bot__flex {
    padding-top: 24px;
    border-top: 1px solid #5e7e9d;
    flex-direction: column;
    align-items: center;
  }
  .footer__text {
    margin-bottom: 12px;
  }
  .footer__policy {
    justify-content: center;
  }
  .footer__bot {
    padding-bottom: 24px;
  }
}
.articles {
  padding-top: 28px;
  padding-bottom: 48px;
}

.recent__title--all {
  justify-content: flex-start;
}

.articles__count {
  padding: 6px 12px;
  background-color: #227ee0;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  border-radius: 6px;
}

.preview--all {
  width: 100%;
  height: 258px;
}

.all__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 46px;
}

.pag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.pag ul {
  display: flex;
}
.pag ul li {
  margin: 0 8px;
}
.pag ul a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border: 1px solid #e2e7ed;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  transition: 0.1s ease-in-out;
}
.pag ul a:hover {
  border-color: #227ee0;
}
.pag ul .pag__active {
  background-color: #227ee0;
  color: #fff;
  border-color: #227ee0;
  pointer-events: none;
}

.pag__arr {
  opacity: 0.5;
  pointer-events: none;
}
.pag__arr svg {
  display: block;
}

.pag__arr--left svg {
  transform: rotate(180deg);
}

.pag__arr--active {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 1249px) {
  .preview--all {
    height: 20.5vw;
  }
  .all__grid {
    gap: 16px;
  }
}
@media (max-width: 991px) {
  .all__grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .preview--all {
    height: 36vw;
    min-height: 215px;
  }
}
@media (max-width: 600px) {
  .all__grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .preview--all {
    height: 75vw;
  }
  .articles {
    padding-top: 8px;
  }
  .recent__title--all {
    justify-content: space-between;
  }
}
@media (max-width: 374px) {
  .pag ul li {
    margin: 0 5px;
  }
}
.content-sec {
  padding-bottom: 15px;
}

.wp-block-columns {
  display: grid;
  column-gap: 52px;
  grid-template-columns: repeat(2, auto);
  margin-bottom: 48px;
}

@media (max-width: 1024px) {
  .wp-block-columns {
    grid-template-columns: repeat(1, auto);
  }
}
.content {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  padding-bottom: 25px;
  color: #000;
}
.content a {
  color: #227ee0;
  text-decoration: underline;
}
.content h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 24px;
  margin-top: 34px;
}
.content .wp-block-columns h2 {
  margin-top: 0;
}
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: 700;
  line-height: 1.3;
  margin-top: 32px;
  margin-bottom: 16px;
}
.content h3 {
  font-size: 18px;
}
.content h4 {
  font-size: 16px;
}
.content h5 {
  font-size: 14px;
}
.content h6 {
  font-size: 12px;
}
.content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.03em;
  margin-bottom: 16px;
  margin-top: 15px;
}
.content blockquote {
  margin: 0;
}
.content q,
.content cite {
  display: block;
  margin-right: 0;
  margin-left: 0;
  margin-top: 24px;
  margin-bottom: 32px;
  background-color: rgb(212, 226, 248);
  padding: 16px;
  border-radius: 16px;
  font-style: italic;
}
.content q:before,
.content cite:before {
  display: none;
}
.content q:after,
.content cite:after {
  display: none;
}
.content img {
  margin-bottom: 24px;
}
.content pre {
  width: 100%;
  overflow: auto;
}
.content figure {
  margin: 0;
}

@media (max-width: 767px) {
  .content h2 {
    font-size: 21px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .wp-block-columns {
    margin-bottom: 24px;
  }
}
.wp-block-embed__wrapper,
.wp-block-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 або інший співвідношення сторін */
  height: 0;
  margin-bottom: 8px;
}

.wp-block-embed__wrapper iframe,
.wp-block-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feadback__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.feadback__modal.active {
  display: flex;
}

.feadback__modal__wrap {
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
  position: relative;
  text-align: center;
}

.feadback__modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.comments {
  background-color: #111e2c;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #fff;
}

.comment__item {
  padding: 36px 24px;
  background-color: #f1faff;
  color: #121921;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
}

.comments__author {
  flex: 1;
  display: flex;
  align-items: center;
}

.comment__des {
  width: 100%;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.03em;
}

.comments__author__name {
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.03em;
  display: flex;
  align-items: center;
}
.comments__author__name:after {
  content: "";
  display: block;
  height: 25px;
  border-right: 1px solid #121921;
  margin-left: 24px;
  margin-right: 24px;
}

.comments__author__job {
  font-size: 24px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: -0.03em;
}

.comment__date {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  padding-left: 15px;
}
.comment__date svg {
  margin-right: 12px;
}

.comment__item:nth-child(2n) {
  background-color: transparent;
  color: #fff;
}
.comment__item:nth-child(2n) .comment__date svg {
  fill: #fff;
}
.comment__item:nth-child(2n) .comments__author__name:after {
  border-color: #fff;
}

.comment__list {
  margin-bottom: 36px;
}

.comments__more {
  display: flex;
  justify-content: center;
}
.comments__more .btn-primary {
  display: flex;
  align-items: center;
  padding: 11px 14px;
}
.comments__more .btn-primary svg {
  margin-left: 8px;
}

.comments__t {
  margin: 0;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin-bottom: 36px;
}

@media (max-width: 991px) {
  .comment__date {
    order: 3;
    width: 100%;
    padding-left: 0;
    margin-top: 24px;
  }
  .comments__author {
    flex-direction: column;
    align-items: flex-start;
  }
  .comments__author__name {
    margin-bottom: 4px;
  }
  .comments__author__name::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .comments__t {
    font-size: 21px;
    line-height: 32px;
  }
  .comments {
    padding-top: 38px;
    padding-bottom: 38px;
  }
  .comment__item {
    padding: 24px;
  }
  .comments__author__name {
    font-size: 21px;
    line-height: 33px;
  }
  .comments__author__job {
    font-size: 16px;
    line-height: 25px;
  }
  .comment__des {
    margin-top: 23px;
  }
}
.feadback {
  background-color: #f7f9fb;
  padding-top: 80px;
  padding-bottom: 80px;
}

.feadback__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0;
  margin-bottom: 40px;
}

.feadback__text {
  margin-top: 24px;
  padding-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #76838b;
}
.feadback__text a {
  color: #227ee0;
  text-decoration: underline;
}

.feadback__inputs__item {
  position: relative;
}
.feadback__inputs__item input {
  border-radius: 4px 4px 0 0;
  height: 48px;
  width: 100%;
  padding: 14px 16px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  outline: none;
}
.feadback__inputs__item input:focus {
  border-bottom: 1px solid #227ee0;
}
.feadback__inputs__item .feadback__label {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #76838b;
  transition: 0.3s all;
}
.feadback__inputs__item textarea {
  height: 199px;
  resize: none;
  width: 100%;
  display: block;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  border-radius: 4px 4px 0 0;
}
.feadback__inputs__item textarea::placeholder {
  color: #76838b;
}
.feadback__inputs__item textarea:focus {
  outline: none;
  border-bottom: 1px solid #227ee0;
}
.feadback__inputs__item input:focus + .feadback__label {
  top: -3px;
  font-size: 9px;
  line-height: 9px;
}
.feadback__inputs__item textarea:focus + .feadback__label {
  top: -3px;
  font-size: 9px;
  line-height: 9px;
}
.feadback__inputs__item input:not(:placeholder-shown) + .feadback__label {
  top: -3px;
  font-size: 9px;
  line-height: 9px;
}
.feadback__inputs__item textarea:not(:placeholder-shown) + .feadback__label {
  top: -3px;
  font-size: 9px;
  line-height: 9px;
}

.feadback__inputs__item.feadback__inputs__item--selector {
  position: relative;
}
.feadback__inputs__item.feadback__inputs__item--selector input {
  cursor: pointer;
  background-image: url("../img/post/v.svg");
  background-repeat: no-repeat;
  background-position: right 14px top 17px;
  padding-right: 25px;
}
.feadback__inputs__item.feadback__inputs__item--selector input:focus {
  outline: none;
}

.feadback__inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 28px 48px;
  margin-bottom: 40px;
}

.feadback__inputs__item__options {
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2509803922);
  border-top: 2px solid #227ee0;
  display: none;
  position: absolute;
  border-radius: 0 0 6px;
  overflow: hidden;
}

.feadback__inputs__item__options.active {
  display: block;
}

.feadback__inputs__item__options__item {
  padding: 13px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
.feadback__inputs__item__options__item:last-child {
  border-bottom: 0;
}
.feadback__inputs__item__options__item:hover {
  background-color: #f0f0f0;
}

@media (max-width: 1199px) {
  .feadback__inputs {
    gap: 28px 28px;
  }
}
@media (max-width: 991px) {
  .feadback__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .feadback__inputs {
    grid-template-columns: repeat(1, 1fr);
  }
  .feadback__title {
    text-align: center;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
  .feadback {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: auto;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.modal.active {
  display: block;
}

.modal__content {
  min-height: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.modal__wrap {
  max-width: 586px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 24px 36px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.modal__close {
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  top: 18px;
  right: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__close:hover {
  background-color: #eaf6ff;
}

.modal__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 24px;
  padding-right: 30px;
}

.modal__row {
  margin-bottom: 24px;
}
.modal__row:last-child {
  margin-bottom: 0;
}

.modal__input {
  position: relative;
}
.modal__input input {
  height: 48px;
  background-color: #eaf6ff;
  border: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.3px;
  border-bottom: 1px solid transparent;
  transition: 0.3s all;
}
.modal__input input.just-validate-error-field {
  background-color: rgb(255, 225, 225);
  border-bottom: 1px solid rgb(255, 0, 0);
}
.modal__input input + .modal__label {
  position: absolute;
  left: 16px;
  top: 18px;
  color: #76838b;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  transition: 0.3s all;
  pointer-events: none;
}
.modal__input input:focus + .modal__label {
  font-size: 10px;
  line-height: 10px;
  top: -3px;
}
.modal__input input:not(:placeholder-shown) + .modal__label {
  font-size: 10px;
  line-height: 10px;
  top: -3px;
}
.modal__input input:focus {
  border-bottom: 1px solid #227ee0;
  outline: none;
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -99;
}
.checkbox input + .checkbox__check {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #76838b;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s all;
  margin-right: 12px;
  position: relative;
  top: -1px;
}
.checkbox input + .checkbox__check:after {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 100%;
  transition: 0.1s all;
}
@media (max-width: 500px) {
  .checkbox input + .checkbox__check {
    margin-right: 5px;
  }
}
.checkbox input:checked + .checkbox__check {
  border-color: #227ee0;
}
.checkbox input:checked + .checkbox__check:after {
  background-color: #227ee0;
}

.checkbox__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: #76838b;
  display: flex;
  align-items: center;
  min-height: 20px;
}

.modal__link {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  display: inline-block;
  color: #227ee0;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.modal__link:hover {
  text-decoration: underline;
}

.login__remember {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login__accept {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 34px;
}
.login__accept a {
  margin-left: 5px;
}

.modal__sub {
  margin-bottom: 24px;
}
.modal__sub button,
.modal__sub a {
  margin-bottom: 12px;
  width: 100%;
}
.modal__sub button:last-child,
.modal__sub a:last-child {
  margin-bottom: 0;
}

.modal__bot {
  display: flex;
  justify-content: center;
}
.modal__bot .modal__link {
  margin-left: 19px;
}

.modal__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #121921;
}

.passToggle {
  position: absolute;
  top: 12px;
  right: 16px;
  padding: 0;
  background-color: transparent;
  border: 0;
}
.passToggle .pass__show {
  display: none;
}

.passToggle.active .pass__hide {
  display: none;
}
.passToggle.active .pass__show {
  display: block;
}

@media (max-width: 600px) {
  .modal__wrap {
    padding: 24px 15px;
  }
  .modal__close {
    right: 7px;
  }
  .modal__link,
  .modal__text {
    font-size: 14px;
    line-height: 1.3;
  }
}
@media (max-width: 374px) {
  .modal__bot {
    flex-direction: column;
    align-items: center;
  }
  .modal__bot .modal__link {
    margin-left: 0;
    margin-top: 12px;
  }
}
.modal__create .modal__row {
  margin-bottom: 12px;
}

.modal__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.modal__col {
  width: calc(50% - 12px);
  margin: 0 6px;
  margin-bottom: 12px;
}

.just-validate-error-label,
.errorText {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: rgb(255, 0, 0);
  margin-top: 2px;
  max-width: 100%;
}

.modal__type {
  margin-bottom: 12px;
}

.modal__type__btn {
  padding: 6px 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #888888;
  display: block;
  text-align: center;
  transition: 0.3s all;
  position: relative;
  width: 133px;
}
.modal__type__btn:hover {
  color: #227ee0;
}
.modal__type__btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: #888888;
  border-radius: 5px 5px 0 0;
}

.modal__type__btn__active {
  color: #227ee0;
}
.modal__type__btn__active:after {
  background-color: #227ee0;
}

.modal__type__left {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.modal__type__right {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 500px) {
  .modal__col {
    width: calc(100% - 12px);
  }
  .modal__type .modal__col {
    width: calc(50% - 12px);
  }
  .modal__type__btn {
    font-size: 12px;
    line-height: 14px;
  }
  .login__accept {
    justify-content: flex-start;
  }
}
.authModal__error {
  display: none;
}

.authModal__error.active {
  display: block;
}

.ressetModal__error {
  display: none;
}

.ressetModal__error.active {
  display: block;
}

.ressetModal__send {
  display: none;
  margin-bottom: 15px;
}

.ressetModal__send.active {
  display: block;
}

.main {
  padding-top: 64px;
}

.swiper .swiper-slide {
  width: auto;
}
.swiper .swiper-scrollbar {
  width: 164px;
  height: 4px;
  margin: 0 23px;
  position: static;
}
.swiper .swiper-scrollbar-drag:after {
  background: rgb(34, 126, 224);
}
.swiper .swiper__bot {
  padding-top: 32px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  position: static;
  transform: translate(0);
  margin: 0;
  color: #227ee0;
  width: 24px;
  height: 24px;
}
.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  font-size: 15px;
  font-weight: bold;
}