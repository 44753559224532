.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: auto;
  padding: 15px;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: none;
}

.modal.active {
  display: block;
}

.modal__content {
  min-height: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.modal__wrap {
  max-width: 586px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 24px 36px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.modal__close {
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  top: 18px;
  right: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #eaf6ff;
  }
}
.modal__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 24px;
  padding-right: 30px;
}

.modal__row {
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}

.modal__input {
  position: relative;
  input {
    height: 48px;
    background-color: #eaf6ff;
    border: 1px solid #f0f0f0;
    border-radius: 4px 4px 0 0;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.3px;
    border-bottom: 1px solid transparent;
    transition: 0.3s all;
  }
  input.just-validate-error-field {
    background-color: rgba(255, 225, 225, 1);
    border-bottom: 1px solid rgba(255, 0, 0, 1);
  }

  input + .modal__label {
    position: absolute;
    left: 16px;
    top: 18px;
    color: #76838b;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    transition: 0.3s all;
    pointer-events: none;
  }

  input:focus + .modal__label {
    font-size: 10px;
    line-height: 10px;
    top: -3px;
  }
  input:not(:placeholder-shown) + .modal__label {
    font-size: 10px;
    line-height: 10px;
    top: -3px;
  }
  input:focus {
    border-bottom: 1px solid #227ee0;
    outline: none;
  }
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    z-index: -99;
  }
  input + .checkbox__check {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #76838b;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s all;
    margin-right: 12px;
    position: relative;
    top: -1px;
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 100%;
      transition: 0.1s all;
    }

    @media (max-width: 500px) {
      & {
        margin-right: 5px;
      }
    }
  }
  input:checked + .checkbox__check {
    border-color: #227ee0;
    &:after {
      background-color: #227ee0;
    }
  }
}
.checkbox__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: #76838b;
  display: flex;
  align-items: center;
  min-height: 20px;
}

.modal__link {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  display: inline-block;
  color: #227ee0;
  border: 0;
  padding: 0;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
}

.login__remember {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login__accept {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 34px;
  a {
    margin-left: 5px;
  }
}

.modal__sub {
  margin-bottom: 24px;

  button,
  a {
    margin-bottom: 12px;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal__bot {
  display: flex;
  justify-content: center;
  .modal__link {
    margin-left: 19px;
  }
}

.modal__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #121921;
}

.passToggle {
  position: absolute;
  top: 12px;
  right: 16px;
  padding: 0;
  background-color: transparent;
  border: 0;
  .pass__show {
    display: none;
  }
}
.passToggle.active {
  .pass__hide {
    display: none;
  }
  .pass__show {
    display: block;
  }
}
@media (max-width: 600px) {
  .modal__wrap {
    padding: 24px 15px;
  }

  .modal__close {
    right: 7px;
  }
  .modal__link,
  .modal__text {
    font-size: 14px;
    line-height: 1.3;
  }
}

@media (max-width: 374px) {
  .modal__bot {
    flex-direction: column;
    align-items: center;
    .modal__link {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}

.modal__create {
  .modal__row {
    margin-bottom: 12px;
  }
}

.modal__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}
.modal__col {
  width: calc(50% - 12px);
  margin: 0 6px;
  margin-bottom: 12px;
}
.just-validate-error-label,
.errorText {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: rgba(255, 0, 0, 1);
  margin-top: 2px;
  max-width: 100%;
}

.modal__type {
  margin-bottom: 12px;
}

.modal__type__btn {
  padding: 6px 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #888888;
  display: block;
  text-align: center;
  transition: 0.3s all;
  position: relative;
  width: 133px;
  &:hover {
    color: #227ee0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: #888888;
    border-radius: 5px 5px 0 0;
  }
}

.modal__type__btn__active {
  color: #227ee0;
  &:after {
    background-color: #227ee0;
  }
}

.modal__type__left {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.modal__type__right {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 500px) {
  .modal__col {
    width: calc(100% - 12px);
  }
  .modal__type {
    .modal__col {
      width: calc(50% - 12px);
    }
  }
  .modal__type__btn {
    font-size: 12px;
    line-height: 14px;
  }
  .login__accept {
    justify-content: flex-start;
  }
}

.authModal__error {
  display: none;
}
.authModal__error.active {
  display: block;
}
.ressetModal__error {
  display: none;
}
.ressetModal__error.active {
  display: block;
}

.ressetModal__send {
  display: none;
  margin-bottom: 15px;
}
.ressetModal__send.active {
  display: block;
}
