.content-sec {
  padding-bottom: 15px;
}
.wp-block-columns {
  display: grid;
  column-gap: 52px;
  grid-template-columns: repeat(2, auto);
  margin-bottom: 48px;
}

@media (max-width: 1024px) {
  .wp-block-columns {
    grid-template-columns: repeat(1, auto);
  }
}
.content {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  padding-bottom: 25px;
  color: #000;
  a {
    color: #227ee0;
    text-decoration: underline;
  }
  h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 24px;
    margin-top: 34px;
  }
  .wp-block-columns {
    h2 {
      margin-top: 0;
    }
  }
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: 1.3;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    margin-bottom: 16px;
    margin-top: 15px;
  }
  blockquote {
    margin: 0;
  }
  q,
  cite {
    display: block;
    margin-right: 0;
    margin-left: 0;
    margin-top: 24px;
    margin-bottom: 32px;
    background-color: rgba(212, 226, 248, 1);
    padding: 16px;
    border-radius: 16px;
    font-style: italic;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  img {
    margin-bottom: 24px;
  }
  pre {
    width: 100%;
    overflow: auto;
  }
  figure {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .content {
    h2 {
      font-size: 21px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }
  .wp-block-columns {
    margin-bottom: 24px;
  }
}

.wp-block-embed__wrapper,
.wp-block-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 або інший співвідношення сторін */
  height: 0;
  margin-bottom: 8px;
}

.wp-block-embed__wrapper iframe,
.wp-block-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feadback__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.feadback__modal.active {
  display: flex;
}
.feadback__modal__wrap {
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
  position: relative;
  text-align: center;
}

.feadback__modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.comments {
  background-color: #111e2c;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #fff;
}

.comment__item {
  padding: 36px 24px;
  background-color: #f1faff;
  color: #121921;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
}

.comments__author {
  flex: 1;
  display: flex;
  align-items: center;
}

.comment__des {
  width: 100%;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.03em;
}

.comments__author__name {
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.03em;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    display: block;
    height: 25px;
    border-right: 1px solid #121921;
    margin-left: 24px;
    margin-right: 24px;
  }
}

.comments__author__job {
  font-size: 24px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: -0.03em;
}

.comment__date {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  padding-left: 15px;
  svg {
    margin-right: 12px;
  }
}

.comment__item:nth-child(2n) {
  background-color: transparent;
  color: #fff;
  .comment__date {
    svg {
      fill: #fff;
    }
  }
  .comments__author__name:after {
    border-color: #fff;
  }
}

.comment__list {
  margin-bottom: 36px;
}

.comments__more {
  display: flex;
  justify-content: center;
  .btn-primary {
    display: flex;
    align-items: center;
    padding: 11px 14px;
    svg {
      margin-left: 8px;
    }
  }
}

.comments__t {
  margin: 0;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin-bottom: 36px;
}

@media (max-width: 991px) {
  .comment__date {
    order: 3;
    width: 100%;
    padding-left: 0;
    margin-top: 24px;
  }
  .comments__author {
    flex-direction: column;
    align-items: flex-start;
  }
  .comments__author__name {
    margin-bottom: 4px;
  }
  .comments__author__name::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .comments__t {
    font-size: 21px;
    line-height: 32px;
  }
  .comments {
    padding-top: 38px;
    padding-bottom: 38px;
  }
  .comment__item {
    padding: 24px;
  }
  .comments__author__name {
    font-size: 21px;
    line-height: 33px;
  }
  .comments__author__job {
    font-size: 16px;
    line-height: 25px;
  }
  .comment__des {
    margin-top: 23px;
  }
}

.feadback {
  background-color: #f7f9fb;
  padding-top: 80px;
  padding-bottom: 80px;
}

.feadback__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0;
  margin-bottom: 40px;
}

.feadback__text {
  margin-top: 24px;
  padding-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #76838b;
  a {
    color: #227ee0;
    text-decoration: underline;
  }
}

.feadback__inputs__item {
  position: relative;
  input {
    border-radius: 4px 4px 0 0;
    height: 48px;
    width: 100%;
    padding: 14px 16px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    outline: none;
    &:focus {
      border-bottom: 1px solid #227ee0;
    }
  }
  .feadback__label {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #76838b;
    transition: 0.3s all;
  }
  textarea {
    height: 199px;
    resize: none;
    width: 100%;
    display: block;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    border-radius: 4px 4px 0 0;
    &::placeholder {
      color: #76838b;
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid #227ee0;
    }
  }
  input:focus + .feadback__label {
    top: -3px;
    font-size: 9px;
    line-height: 9px;
  }
  textarea:focus + .feadback__label {
    top: -3px;
    font-size: 9px;
    line-height: 9px;
  }
  input:not(:placeholder-shown) + .feadback__label {
    top: -3px;
    font-size: 9px;
    line-height: 9px;
  }
  textarea:not(:placeholder-shown) + .feadback__label {
    top: -3px;
    font-size: 9px;
    line-height: 9px;
  }
}
.feadback__inputs__item.feadback__inputs__item--selector {
  position: relative;
  input {
    cursor: pointer;
    background-image: url("../img/post/v.svg");
    background-repeat: no-repeat;
    background-position: right 14px top 17px;
    padding-right: 25px;
    &:focus {
      outline: none;
    }
  }
}

.feadback__inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 28px 48px;
  margin-bottom: 40px;
}

.feadback__inputs__item__options {
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 2px 7px 0px #00000040;
  border-top: 2px solid #227ee0;
  display: none;
  position: absolute;
  border-radius: 0 0 6px;
  overflow: hidden;
}

.feadback__inputs__item__options.active {
  display: block;
}

.feadback__inputs__item__options__item {
  padding: 13px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}

@media (max-width: 1199px) {
  .feadback__inputs {
    gap: 28px 28px;
  }
}

@media (max-width: 991px) {
  .feadback__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .feadback__inputs {
    grid-template-columns: repeat(1, 1fr);
  }
  .feadback__title {
    text-align: center;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
  .feadback {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}
