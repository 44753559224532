.action-sec {
  .sec {
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
  }
  .secDark {
    background-color: rgba(13, 40, 67, 1);
    color: rgba(255, 255, 255, 1);
  }
  .secLight {
    color: rgba(13, 40, 67, 1);
    background-color: rgba(255, 255, 255, 1);
    .t1 {
      color: rgba(18, 25, 33, 0.36);
    }
  }
  .bot {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    a {
      margin: 0 12px;
      font-weight: 500;
    }
  }

  .wrap {
    max-width: 830px;
    width: 100%;
    margin: 0 auto;
  }

  .t1 {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
  }
  .lightt1 {
    color: rgba(18, 25, 33, 0.36);
  }
  .darkt1 {
    color: rgba(255, 255, 255, 0.36);
  }

  .t2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 47px;
    margin-bottom: 24px;
  }

  .t3 {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    a {
      font-weight: 500;
      text-decoration: underline;
      display: inline-block;
      color: #fff;
    }
  }
  .light {
    a {
      color: #227ee0;
    }
  }
  @media (max-width: 767px) {
    .t1 {
      font-size: 18px;
      line-height: 24px;
      max-width: 317px;
      margin-left: auto;
      margin-right: auto;
    }
    .t3 {
      font-size: 21px;
      line-height: 38px;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
      a {
        display: block;
      }
    }
    .t2 {
      font-size: 21px;
      line-height: 27px;
      max-width: 345px;
      margin-left: auto;
      margin-right: auto;
    }

    .bot {
      flex-direction: column;
      a {
        margin: 0;
        margin-bottom: 16px;
        display: block;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sec {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
