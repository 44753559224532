.about {
  padding-top: 40px;
}
.about__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 0 0 40px;
}

.about__item {
  background-color: #111e2c;
  border-radius: 12px;
  display: flex;
  padding: 29px 21px 24px 24px;
}

.about__img {
  display: block;
  flex: 0 0 213px;
  background-color: #f4faff;
  border-radius: 12px;
  padding: 20px;
  overflow: hidden;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #121921;
  margin-right: 24px;
  transition: 0.3s all;
  &:hover {
    background-color: #f4f9fd;
  }
  img {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.about__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 24px;
  color: #fff;
}
.about__content {
  flex: 1;
}
.about__bot {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  .btn-primary {
    padding: 11px 20px;
  }
  .about__more {
    background-color: transparent;
  }
}
.about__grid {
  margin: 0 -15px;
  padding: 0 15px;
}
@media (min-width: 1250px) {
  .about__grid {
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 46px;
    }
  }
}
.about .swiper__bot {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 1249px) {
  .about__item {
    width: 324px;
    flex-direction: column;
    padding: 24px;
  }
  .about__img {
    flex: auto;
    margin-right: 0;
    width: 100%;
    height: 179px;
    margin-bottom: 24px;
  }
  .about__content {
    flex: auto;
  }
  .about__bot {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
    .btn-primary:last-child {
      margin-bottom: 12px;
    }
  }
  .about .swiper__bot {
    opacity: 1;
    pointer-events: all;
  }
}

@media (max-width: 767px) {
  .about__title {
    font-size: 21px;
    line-height: 32px;
    margin-bottom: 33px;
  }
}

@media (max-width: 374px) {
  .about__item {
    width: 280px;
    padding: 20px 15px;
  }
}
