.articles {
  padding-top: 28px;
  padding-bottom: 48px;
}

.recent__title--all {
  justify-content: flex-start;
}

.articles__count {
  padding: 6px 12px;
  background-color: #227ee0;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #fff;
  border-radius: 6px;
}

.preview--all {
  width: 100%;
  height: 258px;
}

.all__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 46px;
}

.pag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  ul {
    display: flex;
    li {
      margin: 0 8px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border: 1px solid #e2e7ed;
      border-radius: 4px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      transition: 0.1s ease-in-out;
      &:hover {
        border-color: #227ee0;
      }
    }
    .pag__active {
      background-color: #227ee0;
      color: #fff;
      border-color: #227ee0;
      pointer-events: none;
    }
  }
}

.pag__arr {
  opacity: 0.5;
  pointer-events: none;
  svg {
    display: block;
  }
}

.pag__arr--left {
  svg {
    transform: rotate(180deg);
  }
}

.pag__arr--active {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 1249px) {
  .preview--all {
    height: 20.5vw;
  }
  .all__grid {
    gap: 16px;
  }
}

@media (max-width: 991px) {
  .all__grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .preview--all {
    height: 36vw;
    min-height: 215px;
  }
}
@media (max-width: 600px) {
  .all__grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .preview--all {
    height: 75vw;
  }
  .articles {
    padding-top: 8px;
  }
  .recent__title--all {
    justify-content: space-between;
  }
}

@media (max-width: 374px) {
  .pag {
    ul {
      li {
        margin: 0 5px;
      }
    }
  }
}
