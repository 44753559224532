.mainsection {
  .label {
    padding: 12px 19px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: #121921;
    display: inline-block;
    border-radius: 3px;
  }
  .sec {
    padding-top: 168px;
    padding-bottom: 168px;
    min-height: 680px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .sec__des,
  .sec__mob {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
  .sec__mob {
    display: none;
  }

  @media (max-width: 575px) {
    .sec__mob {
      display: block;
    }
    .sec__des {
      display: none;
    }
  }
  @media (min-width: 1440px) {
    .sec {
      min-height: calc(100vh - 60px);
    }
  }

  @media (min-width: 2400px) {
    .sec {
      min-height: 1200px;
    }
  }

  .wrap {
    position: relative;
    z-index: 2;
    h1 {
      font-size: 100px;
      font-weight: 500;
      line-height: 130px;
      margin: 0;
      color: #fff;
      span {
        font-size: 80px;
        font-weight: 400;
        line-height: 82px;
        display: block;
      }
    }
  }

  .des {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 30px;
    color: #fff;
    svg {
      fill: #fff;
      position: relative;
      top: 4px;
      margin-right: 12px;
      display: inline-block;
    }
  }

  .btns {
    display: flex;
    align-items: flex-start;
    a {
      margin-right: 24px;
      font-weight: 500;
    }
  }

  .smartLine {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 1;
  }

  @media (max-width: 767px) {
    .label {
      margin-bottom: 16px;
    }
    .smartLine {
      display: none;
    }
    .sec {
      padding-top: 232px;
      padding-bottom: 25px;
      min-height: 488px;
    }
    .wrap {
      h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 12px;
        span {
          font-size: 27px;
          line-height: 32px;
        }
      }
    }
    .des {
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      max-width: 400px;
      br {
        display: none;
      }
    }
    .btns {
      flex-direction: column;
      a {
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sec--post {
      padding-bottom: 46px;
      min-height: initial;
      .des {
        margin-bottom: 0;
      }
    }
  }
}
