.download-sec {
  .bot__right {
    border-radius: 6px;
    overflow: hidden;
    img {
      display: block;
    }
  }
  .download {
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #111e2c;
    color: #fff;
    @media (max-width: 767px) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    h2 {
      font-size: 64px;
      font-weight: 500;
      line-height: 83px;
      margin-bottom: 8px;
      .mini {
        font-size: 34px;
        font-weight: 500;
        line-height: 44px;
      }
      .blue {
        color: rgba(33, 116, 205, 1);
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }
  .flex__left {
    flex: 1;
    padding-right: 18px;
  }
  .flex__right {
    width: 616px;
  }

  .benefits {
    display: flex;
    align-items: flex-start;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(157, 178, 200, 1);
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .benefits__col {
    margin-right: 15px;
    margin-bottom: 16px;
  }

  .bot {
    display: flex;
    align-items: flex-start;
  }

  .bot__link {
    margin-bottom: 16px;
    display: block;
    transition: 0.3s all;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid rgba(33, 116, 205, 0.24);
    img {
      width: 188px;
      height: 57px;
      display: block;
    }
    &:hover {
      box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .bot__left {
    margin-right: 20px;
  }

  .video {
    display: block;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.3s all;

    // &:hover {
    //   box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.5);
    // }
  }

  .video__pl {
    max-width: 100%;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding-left: 7px;
  }

  @media (max-width: 1199px) {
    .flex__right {
      width: 500px;
    }
  }

  @media (max-width: 1023px) {
    .flex {
      flex-direction: column;
      align-items: flex-start;
    }
    .flex__left {
      flex: auto;
      width: 100%;
      padding-right: 0;
      margin-bottom: 16px;
      text-align: center;
    }
    .benefits {
      justify-content: center;
      margin-bottom: 16px;
    }
    .benefits__col {
      margin: 0 15px;
    }
    .bot {
      justify-content: center;
    }
    .bot__right {
      display: none;
    }
    .bot__left {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 24px;
      margin-right: 0;
    }
    .bot__link {
      margin-bottom: 0;
    }
    .flex__right {
      width: 616px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    .download {
      h2 {
        font-size: 43px;
        line-height: 56px;
        .mini {
          font-size: 24px;
          line-height: 31px;
        }
      }
    }
    .benefits__col {
      font-size: 16px;
      line-height: 24px;
    }
    .bot__link {
      img {
        width: 159px;
        height: 45px;
      }
    }
  }

  @media (max-width: 400px) {
    .download {
      h2 {
        font-size: 36px;
        line-height: 46px;
        .mini {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    .bot__left {
      grid-template-columns: repeat(1fr, auto);
    }
    .bot__link {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
