.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0d2843;
  z-index: 5;
}

.header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header__ul {
  display: flex;
}

.header__li {
  padding: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-right: 24px;
  cursor: pointer;
  transition: 0.3s all;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #66acf5;
    opacity: 0;
    transition: 0.3s all;
  }
  &:last-child {
    margin-right: 0;
  }
  & > svg {
    transition: 0.2s all;
    margin-left: 4px;
  }
  &:hover {
    color: #66acf5;
    &:after {
      opacity: 1;
    }
    & > svg {
      transform: rotate(180deg);
    }
  }
}

.header__phone {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #fff;
  display: block;
  transition: 0.3s all;
  margin-right: 24px;
  &:hover {
    color: #66acf5;
  }
}

.header__right {
  display: flex;
  align-items: center;
}

.header__btn {
  padding: 6px 16px;
  border-radius: 6px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;

  &:last-child {
    margin-right: 0;
  }
}

.header__li__menu {
  display: none;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  padding: 38px 0;
  background-color: rgba(17, 30, 44, 0.85);
}

.header__li:hover .header__li__menu {
  display: block;
}
.header__li:hover .header__li__menu2 {
  display: block;
}

.header__p {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 39px;
}

.header__p__item {
  padding: 17px 16px;
  border-radius: 9.5px;
  color: #121921;
  transition: 0.3s all;
}

.header__p__t {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 6px;
}

.header__p__text {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.header__p__item--eld {
  background-color: #dfffff;
  &:hover {
    background-color: #aeffff;
  }
}

.header__p__item--dh {
  background-color: #e0ffe9;
  &:hover {
    background-color: #aeffc5;
  }
}

.header__p__item--gps {
  background-color: #fff4df;
  &:hover {
    background-color: #ffe4ae;
  }
}

.header__c {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.header__c__item {
  height: 132px;
  border-radius: 12px;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 25, 33, 1);
  background-repeat: no-repeat;
  transition: 0.3s all;
  svg {
    margin-bottom: 16px;
    display: block;
  }
}

.header__c__item--1 {
  background-color: rgba(223, 255, 255, 1);
  background-image: url("../img/header/1.svg");
  background-position: right bottom;
  &:hover {
    background-color: #aeffff;
  }
}

.header__c__item--2 {
  background-color: rgba(224, 255, 233, 1);
  background-image: url("../img/header/2.svg");
  background-position: right bottom;
  &:hover {
    background-color: #aeffc5;
  }
}

.header__c__item--3 {
  background-color: rgba(255, 244, 223, 1);
  background-image: url("../img/header/3.svg");
  background-position: center;
  background-size: cover;
  &:hover {
    background-color: #ffe4ae;
  }
}

.header__c__item--4 {
  background-color: rgba(224, 239, 255, 1);
  background-image: url("../img/header/4.svg");
  background-position: center;
  background-size: cover;
  &:hover {
    background-color: #aed5ff;
  }
}

.header__c__item--5 {
  background-color: rgba(230, 224, 255, 1);
  background-image: url("../img/header/5.svg");
  background-position: left bottom;
  &:hover {
    background-color: #beafff;
  }
}

.header__c__item--6 {
  background-color: rgba(255, 244, 223, 1);
  background-image: url("../img/header/6.svg");
  background-position: left bottom;
  &:hover {
    background-color: #ffe4ae;
  }
}

.header__s {
  display: flex;
  align-items: center;
  .header__c {
    grid-template-columns: repeat(4, 1fr);
  }
  .header__c__item {
    height: 107px;
    svg {
      margin-bottom: 6px;
    }
  }
}

.header__partners {
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.24);
  padding-left: 24px;
  margin-left: 24px;
  height: 107px;
}

.header__partners__text {
  max-width: 66px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-right: 24px;
  color: #fff;
}

.header__partners__img__2 {
  display: none;
}

.header__partners__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 20px;
}

.header__partners__img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  width: 159px;
  height: 45px;
  span {
    margin-left: 10px;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(63, 63, 63, 1);
  }
  &:hover {
    .header__partners__img__1 {
      display: none;
    }
    .header__partners__img__2 {
      display: block;
    }
  }
}

.header__li__menu2 {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(17, 30, 44, 1);
  display: none;
  border-radius: 0 0 12px 12px;
  li {
    border-bottom: 1px solid rgba(48, 67, 86, 1);
    &:last-child {
      border-bottom: 0;
    }
  }
  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    display: block;
    padding: 11px 16px;
    white-space: nowrap;
    &:hover {
      color: #66acf5;
    }
  }
}

.header__mob {
  display: none;
}

.header__logo__mob {
  display: none;
}
.mob__menu {
  display: none;
}
@media (max-width: 1249px) {
  .header {
    height: 70px;
    display: flex;
    align-items: center;
  }
  .header__mob {
    display: flex;
    align-items: center;
  }

  .header__right {
    display: none;
  }

  .header__nav {
    display: none;
  }
  .header__avatar {
    margin-right: 16px;
  }
  .burger {
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      .burger__open {
        display: none;
      }
      .burger__close {
        display: block;
      }
    }
  }
  .burger__close {
    display: none;
  }
  .header.active {
    .burger__open {
      display: none;
    }
    .burger__close {
      display: block;
    }
    .header__avatar {
      display: none;
    }
    .header__btn {
      display: none;
    }
    .header__logo__des {
      display: block;
    }
    .header__logo__mob {
      display: none;
    }
  }
  .mob__menu__top {
    background-color: #e9f2fc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 9px;
    border-radius: 0 0 10px 10px;
    a {
      margin: 0 6px;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0em;
      display: flex;
      align-items: center;
      color: #121921;
      svg {
        margin-right: 12px;
      }
    }
  }
  .mob__menu {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-color: #fff;
    overflow: auto;
    display: none;
  }
  .mob__menu.active {
    display: block;
  }
  .mob__menu__person {
    padding: 0 15px;
    margin-top: 26px;
    margin-bottom: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mob__menu__avatar {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
    button {
      color: #227ee0;
    }
  }
  .mob__menu__actions {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 8px;
  }
  .mob__menu__logout,
  .mob__menu__enter {
    width: 35px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
  }

  .mob__menu__bot {
    background-color: #eaf6ff;
    padding: 11px 15px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 24px;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .mob__menu__list {
    padding: 0 15px;
    padding-bottom: 90px;
  }
  .mob__menu__list__btn {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;
    border: 0;
    padding-right: 30px;
    transition: 0.3s all;
  }
  .mob__menu__list__icon {
    margin-right: 6px;
    transition: 0.3s all;
  }
  .mob__menu__list__v {
    position: absolute;
    top: 12px;
    right: 12px;
    transition: 0.3s all;
    color: #bdbdbd;
  }
  .mob__menu__list__ul {
    padding-left: 24px;
    display: none;
    a {
      border-left: 1px solid #bdbdbd;
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
      color: #414141;
      padding: 12.5px 24px;
      display: block;
    }
  }
  .mob__menu__list__item.active {
    .mob__menu__list__icon {
      fill: #fff;
    }
    .mob__menu__list__btn {
      color: #fff;
      background-color: #1da5ff;
    }
    .mob__menu__list__v {
      color: #fff;
      transform: rotate(180deg);
    }
    .mob__menu__list__ul {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .header__logo__des {
    display: none;
  }
  .header__logo__mob {
    display: block;
  }
}

@media (max-width: 374px) {
  .mob__menu__top {
    a {
      font-size: 12px;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
  .mob__menu__bot {
    gap: 10px;
    a {
      font-size: 14px;
    }
  }
}

.user {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #eaf7ff;
  border-radius: 4px;
  display: none;
}
.user.active {
  display: flex;
}
.user__link {
  display: flex;
  align-items: center;
  & > img {
    min-width: 20px;
    margin-right: 5px;
  }
}
.user__name {
  padding-right: 5px;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0em;
  max-width: 42px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  word-break: break-word;
}
.user__actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.user__actions {
  display: grid;
  grid-template-columns: repeat(2, 19px);
  gap: 4.3px;
}

.user__actions__btn {
  padding: 0;
  border: 0;
  width: 19px;
  height: 19px;
  border-radius: 3.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mob__menu__logout {
  background-color: #f2f2f2;
}
.mob__menu__enter {
  background-color: #227ee0;
}

.btn-open-login.hide {
  display: none;
}

@media (max-width: 1249px) {
  .user {
    border-radius: 6px;
    padding: 8px;
    margin-right: 18px;
    .user__link > img {
      width: 39px;
      height: 39px;
    }
    .user__name {
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.01em;
      max-width: 70px;
    }
    .user__actions {
      grid-template-columns: repeat(2, 28px);
    }
    .user__actions__btn {
      width: 28px;
      height: 28px;
      img {
        width: 19px;
        height: 19px;
      }
    }
  }
  .header.active {
    .user {
      display: none;
    }
  }
  .mob__menu__avatar {
    .user {
      padding: 0;
      background-color: transparent;
      .user__name {
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        max-width: initial;
      }
    }
  }
}
