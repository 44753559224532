// базовые подключения
@import "mixins";
@import "fonts";
@import "settings";

// подключения компонентов страницы
@import "./components/header";
@import "./components/main";
@import "./components/recent";
@import "./components/action";
@import "./components/about";
@import "./components/download";
@import "./components/footer";
@import "./components/all";
@import "./components/content";
@import "./components/auth";

.main {
  padding-top: 64px;
}
.swiper {
  .swiper-slide {
    width: auto;
  }
  .swiper-scrollbar {
    width: 164px;
    height: 4px;
    margin: 0 23px;
    position: static;
  }
  .swiper-scrollbar-drag:after {
    background: rgba(34, 126, 224, 1);
  }
  .swiper__bot {
    padding-top: 32px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    transform: translate(0);
    margin: 0;
    color: #227ee0;
    width: 24px;
    height: 24px;
    &:after {
      font-size: 15px;
      font-weight: bold;
    }
  }
}
