.breadcrumb {
  padding-top: 48px;
  padding-bottom: 38px;
}

.breadcrumb__ul {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-bottom: 10px;
    padding-right: 10px;
    position: relative;
    color: #262626;
    &:after {
      content: "";
      width: 2px;
      height: 2px;
      position: absolute;
      display: block;
      background-color: #333a43;
      top: 50%;
      right: 4px;
    }
    &:last-child:after {
      display: none;
    }
  }
  a,
  span {
    font-size: 13px;
    font-weight: 300;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }
  a {
    color: #227ee0;
    &:hover {
      text-decoration: underline;
    }
  }
}
.breadcrumb__home {
  svg {
    display: none;
  }
}
@media (max-width: 767px) {
  .breadcrumb {
    padding-top: 24px;
  }
  .breadcrumb__ul {
    li {
      padding-right: 28px;
      &:after {
        right: 13px;
      }
    }
    .breadcrumb__home {
      span {
        display: none;
      }
      svg {
        display: block;
        top: 1px;
        position: relative;
      }
    }
  }
}

.recent__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.02em;
  color: #111e2c;
  margin: 0;
  margin-bottom: 40px;
  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #2174cd;
    display: inline-flex;
    &:hover {
      text-decoration: underline;
    }
    svg {
      margin-left: 8px;
      fill: #2174cd;
    }
  }
}

@media (max-width: 767px) {
  .recent__title {
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
  }
}

.preview {
  width: 317px;
  height: 170px;
  display: block;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1;
    transition: 0.3s ease-in-out;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );

    height: 50%;
  }
}

.previw__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  max-width: none;
  display: block;
}

.previw__icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.preview__bot {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  svg {
    fill: #fff;
    transition: 0.3s ease-in-out;
  }
}

.previw__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.preview:hover {
  &:before {
    height: 100%;
  }
  .previw__img--vector {
    transform: scale(1.2);
  }
  .preview__bot svg {
    width: 26px;
    height: 26px;
  }

  .previw__title {
    font-size: 17px;
    line-height: 26px;
  }
}

.preview--big {
  width: 480px;
  height: 380px;
}

@media (max-width: 767px) {
  .preview--big {
    width: 324px;
    height: 356px;
  }
  .preview {
    margin-bottom: 16px;
  }
}

@media (max-width: 374px) {
  .preview {
    width: 265px;
    height: 140px;
  }
  .preview--big {
    height: 296px;
  }
}

.popular {
  padding-top: 48px;
  overflow: hidden;
}

.preview--popular {
  width: 480px;
  height: 380px;
}

.popular .swiper {
  overflow: visible;
}

@media (max-width: 767px) {
  .popular {
    padding-top: 38px;
  }
  .preview--popular {
    width: 324px;
    height: 170px;
  }
}
@media (max-width: 374px) {
  .preview--popular {
    width: 265px;
    height: 140px;
  }
}
